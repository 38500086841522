import { onBeforeMount, onMounted, reactive, ref, watch } from "vue";
import { StorageKey } from "@/constant";
import { ElMessage } from "element-plus";
import { useStore } from "@/store";
export default {
  props: {
    targetKey: {
      type: String,
      default: ""
    }
  },
  setup: function setup(props) {
    var store = useStore();
    var showSign = ref(false);
    var inProgress = ref(false);
    var saveAccountSign = ref(false);
    var formDom = ref();
    var formData = reactive({
      username: "",
      password: ""
    });
    var formRules = {
      username: [{
        required: true,
        message: "登录账号必须提供"
      }, {
        min: 5,
        max: 20,
        message: "登录账号长度应为5到20个字符"
      }, {
        pattern: /^[a-zA-Z]\w+$/,
        message: "登录账号格式异常"
      }],
      password: [{
        required: true,
        message: "登录密码必须提供"
      }]
    };
    onBeforeMount(function () {
      var account = localStorage.getItem(StorageKey.Login_SaveLocalAccountKey);

      if (account != null) {
        saveAccountSign.value = true;
        formData.username = account;
      }
    });
    onMounted(function () {
      return showSign.value = true;
    });
    watch(function () {
      return formData.username;
    }, function (val) {
      if (saveAccountSign) {
        editLocalAccount(true, val);
      }
    });

    function onSaveSignChanged() {
      editLocalAccount(saveAccountSign.value, formData.username);
    }

    function onLogin() {
      var _formDom$value;

      editLocalAccount(saveAccountSign.value, formData.username);
      (_formDom$value = formDom.value) === null || _formDom$value === void 0 ? void 0 : _formDom$value.validate(function (valid) {
        if (valid) {
          inProgress.value = true;

          var reset = function reset() {
            inProgress.value = false;
            formData.password = "";
          }; //模拟登录


          setTimeout(function () {
            if (formData.username === "admin" && formData.password === "123456") {
              ElMessage.success("登录成功");
            } else {
              ElMessage.error("登录认证失败");
              reset();
            }
          }, 1000);
        } else {
          ElMessage.error("请正确填写登录信息");
        }
      });
    }

    return {
      showSign: showSign,
      inProgress: inProgress,
      saveAccountSign: saveAccountSign,
      formDom: formDom,
      formData: formData,
      formRules: formRules,
      onSaveSignChanged: onSaveSignChanged,
      onLogin: onLogin
    };
  }
};

function editLocalAccount(sign, account) {
  if (sign) {
    localStorage.setItem(StorageKey.Login_SaveLocalAccountKey, account);
  } else {
    localStorage.removeItem(StorageKey.Login_SaveLocalAccountKey);
  }
}